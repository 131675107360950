<template>
  <div id="collapse-service">
    <ConfirmPurchaseRequest
      v-if="confirmPurchaseModal"
      @closeModal="confirmPurchaseModalHandle"
      :unit-price="
        crypto ? Number(crypto.purchase_price_from_us).toLocaleString() : '----'
      "
      :wallet-inventory="
        crypto ? Number(crypto.user_wallet_balance).toLocaleString() : '----'
      "
      :total-price="product_price"
      amount="*"
    />
    <SuccessAlert
      title="خرید با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-show="showSuccessMassage"
    />
    <div class="collapse-service__header">
      <PanelHeader title="خدمات وب مانی" />
    </div>
    <div class="collapse-service__container">
      <div class="account-recharge-and-sales__wrapper">
        <div
          class="account-recharge-and-sales__collapse-header"
          @click.prevent="toggleCollapse"
        >
          <img
            src="../assets/images/uil_arrow-growth.svg"
            alt=""
            class="account-recharge-and-sales__image"
          />
          <div class="account-recharge-and-sales__title">
            شارژ حساب و فروش {{ crypto ? crypto.persian_name : "----" }}
          </div>
          <img
            src="../assets/images/forward-dropdown.svg"
            alt=""
            class="account-recharge-and-sales__dropdown-icon"
            :class="{ rotate: openCollapse }"
          />
        </div>
        <div
          class="account-recharge-and-sales__content-wrapper"
          v-if="openCollapse"
        >
          <div class="column-one">
            <div class="tab-card">
              <div class="tab-header" :class="[left ? 'left' : '']">
                <div class="right-tab" @click="toggleRightSide">
                  شارژ حساب {{ crypto ? crypto.persian_name : "----" }}
                </div>
                <div class="left-tab" @click="toggleLeftSide">
                  فروش {{ crypto ? crypto.persian_name : "----" }}
                </div>
              </div>
              <div class="tab-right-content" v-if="!left">
                <div class="right-tab-content-wrapper">
                  <AccountRechargeSystemPayment :item="crypto" />
                </div>
              </div>
              <div class="tab-left-content" v-if="left">
                <div class="left-tab-content-wrapper">
                  <SellSystemPayment :item="crypto" />
                </div>
              </div>
            </div>
          </div>
          <div class="column-two">
            <div class="information-wrapper">
              <TipsAndWarnings />
            </div>
          </div>
        </div>
      </div>

      <div class="buy-with__wrapper"></div>
    </div>
    <div class="collapse-service__container buy">
      <div class="account-recharge-and-sales__wrapper">
        <div
          class="account-recharge-and-sales__collapse-header"
          @click.prevent="toggleBuyCollapse"
        >
          <img
            src="../assets/images/web-money.svg"
            alt=""
            class="account-recharge-and-sales__image"
          />
          <div class="account-recharge-and-sales__title">
            خرید با {{ crypto ? crypto.persian_name : "----" }}
          </div>
          <img
            src="../assets/images/forward-dropdown.svg"
            alt=""
            class="account-recharge-and-sales__dropdown-icon"
            :class="{ rotate: openBuyCollapse }"
          />
        </div>
        <div
          class="account-recharge-and-sales__content-wrapper"
          v-if="openBuyCollapse"
        >
          <div class="column-one">
            <div class="buy-with__wrapper">
              <div class="buy-with__header">
                خرید با {{ crypto ? crypto.persian_name : "----" }}
              </div>
              <div class="buy-with__content-wrapper">
                <div class="buy-with__content">
                  <div class="buy-with-content__header-card">
                    <div class="buy-with-content__card-col">
                      <div class="buy-with-content__card-content">
                        هر دلار {{ crypto.persian_name }}
                      </div>
                      <div class="buy-with-content__card-content">
                        <span>{{
                          crypto
                            ? Number(
                                crypto.purchase_price_from_us
                              ).toLocaleString()
                            : "----"
                        }}</span
                        >تومان
                      </div>
                    </div>
                    <div class="buy-with-content__card-col">
                      <div class="buy-with-content__card-content">
                        موجودی
                      </div>
                      <div class="buy-with-content__card-content">
                        <span>{{ crypto ? crypto.inventory : "----" }}</span
                        >دلار
                      </div>
                    </div>
                    <div class="buy-with-content__card-col">
                      <div class="buy-with-content__card-content">
                        موجودی تومانی
                      </div>
                      <div class="buy-with-content__card-content">
                        <span>{{
                          crypto
                            ? Number(crypto.tommani_inventory).toLocaleString()
                            : "----"
                        }}</span
                        >تومان
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-wrapper">
                      <div class="input__label">
                        قیمت محصول*
                      </div>
                      <div class="input__wrapper">
                        <input
                          :class="{ error: error.product_price }"
                          type="text"
                          ref="product_price"
                          class="product-price-input"
                          v-model="product_price"
                          @keypress="isNumber($event)"
                        />
                      </div>
                      <div class="error-message">
                        {{ error.product_price }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-link-wrapper">
                      <div class="input__label">
                        لینک محصول یا سرویس
                      </div>
                      <div class="input-link-wrapper">
                        <input
                          :class="{ error: error.product_or_service_link }"
                          type="text"
                          ref="product_or_service_link"
                          class="link-input"
                          v-model="product_or_service_link"
                        />
                      </div>
                      <div class="error-message">
                        {{ error.product_or_service_link }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="i-have-account-wrapper">
                      <div class="check-box-wrapper">
                        <input type="checkbox" v-model="i_have_account" />
                        <div class="check-box-text">
                          حساب کاربری دارم
                        </div>
                      </div>
                      <div class="i-have-account__text-information">
                        چنان چه برای خرید باید به حساب کاربری وارد شویم،تیک بالا
                        را بزنید و اطلاعات را وارد نمایید.
                      </div>
                      <div class="i-have-account__user-name-input-wrapper">
                        <div class="i-have-account__user-name-label">
                          نام کاربری
                        </div>
                        <input
                          type="text"
                          class="i-have-account__user-name-input"
                          :class="[
                            i_have_account ? 'i-have-account-checked' : '',
                            error.user_name ? 'error' : ''
                          ]"
                          v-model="user_name"
                          ref="user_name"
                        />
                      </div>
                      <div class="error-message">
                        {{ error.user_name }}
                      </div>
                      <div class="form-group">
                        <div class="i-have-account__password-label">
                          رمز عبور
                        </div>
                        <input
                          type="text"
                          class="i-have-account__password-input"
                          :class="[
                            i_have_account ? 'i-have-account-checked' : '',
                            error.user_password ? 'error' : ''
                          ]"
                          ref="user_password"
                          v-model="user_password"
                        />
                      </div>
                      <div class="error-message">
                        {{ error.user_password }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="description-wrapper">
                      <div class="description-label">
                        توضیحات
                      </div>
                      <textarea
                        name=""
                        id=""
                        class="description-textarea"
                      ></textarea>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="how-to-pay-section">
                      <div class="how-to-pay__header">
                        نحوه دریافت پول
                      </div>
                      <div class="how-to-pay__selected-section">
                        <div class="how-to-pay__radio-wrapper">
                          <div class="how-to-pay__radio">
                            <input
                              type="radio"
                              id="huey"
                              value="gate"
                              v-model="pay_method"
                            />
                            <label for="huey">درگاه بانکی</label>
                          </div>
                          <div class="how-to-pay__radio">
                            <input
                              type="radio"
                              id="dewey"
                              value="wallet"
                              v-model="pay_method"
                            />
                            <label for="dewey"
                              >کیف پول (موجودی:
                              <span>{{
                                crypto
                                  ? Number(
                                      crypto.user_wallet_balance
                                    ).toLocaleString()
                                  : "----"
                              }}</span
                              >)</label
                            >
                          </div>
                        </div>
                        <div class="how-to-pay__information">
                          با شارژ کیف پول میتوانید پرداخت های خود را بدون رفتن
                          به درگاه بانکی پرداخت کنید.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="pay-button-wrapper">
                    <button class="pay-button" @click.prevent="submitAndPay">
                      ثبت و پرداخت
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column-two">
            <div class="information-wrapper">
              <TipsAndWarnings />
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="buy-with__wrapper">-->

      <!--      </div>-->
    </div>
    <div
      class="page-image-wrapper"
      :class="[openCollapse ? 'none' : '' || openBuyCollapse ? 'none' : '']"
    >
      <img
        src="../assets/images/web-money-page-logo.svg"
        alt=""
        class="page-image"
      />
    </div>
    <!--    <div class="footer">-->
    <!--      <panel-footer />-->
    <!--    </div>-->
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import TipsAndWarnings from "@/components/TipsAndWarnings";
import ConfirmPurchaseRequest from "@/components/Modals/ConfirmPurchaseRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
// import PanelFooter from "@/components/PanelFooter";
import axios from "axios";
import AccountRechargeSystemPayment from "@/components/SystemPaymentServices/AccountRechargeSystemPayment";
import SellSystemPayment from "@/components/SystemPaymentServices/SellSystemPayment";

export default {
  components: {
    SellSystemPayment,
    AccountRechargeSystemPayment,
    TipsAndWarnings,
    PanelHeader,
    ConfirmPurchaseRequest,
    SuccessAlert
  },
  data() {
    return {
      left: false,
      openCollapse: true,
      openBuyCollapse: false,
      product_price: null,
      product_or_service_link: null,
      i_have_account: false,
      user_name: "",
      user_password: "",
      pay_method: "gate",
      confirmPurchaseModal: false,
      showSuccessMassage: false,
      crypto: null,
      error: {}
    };
  },
  methods: {
    toggleRightSide() {
      this.left = false;
    },
    toggleLeftSide() {
      this.left = true;
    },
    toggleCollapse() {
      this.openCollapse = !this.openCollapse;
    },
    toggleBuyCollapse() {
      this.openBuyCollapse = !this.openBuyCollapse;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async confirmPurchaseModalHandle() {
      if (this.pay_method === "gate") {
        console.log("gate");
      }

      if (this.pay_method === "wallet") {
        let data = {
          crypto_id: this.crypto.id,
          product_price: this.product_price,
          product_link: this.product_or_service_link,
          user_name: this.user_name,
          user_password: this.user_password,
          description: this.description
        };

        let loader = this.$loading.show();
        try {
          let response = await axios({
            url: `/product-purchase?pay_method=wallet&type=product_pruchase&order_method=product_pruchase`,
            method: "POST",
            data: data
          });
          if (!response.data.status) {
            loader.hide();
            return false;
          }
          this.product_price = null;
          this.product_or_service_link = null;
          this.description = null;
          this.user_name = null;
          this.user_password = null;
          loader.hide();
          this.confirmPurchaseModal = false;
          this.showSuccessMassage = true;
          setTimeout(() => {
            this.showSuccessMassage = false;
          }, 2000);
        } catch (error) {
          loader.hide();
          this.confirmPurchaseModal = false;
          console.log(error.data);
        }
      }
    },
    submitAndPay() {
      this.error = {};
      if (!this.product_price) {
        this.error.product_price = "قیمت محصول را وارد کنید";
        this.$refs.product_price.focus();
        return false;
      }

      if (!this.product_or_service_link) {
        this.error.product_or_service_link = "لینک محصول را وارد کنید";
        this.$refs.product_or_service_link.focus();
        return false;
      }

      if (this.i_have_account && !this.user_name) {
        this.error.user_name = "نام کاربر را وارد کنید";
        this.$refs.user_name.focus();
        return false;
      }

      if (this.i_have_account && !this.user_password) {
        this.error.user_password = "رمز عبور را وارد کنید";
        this.$refs.user_password.focus();
        return false;
      }

      if (this.pay_method === "wallet") {
        // console.log("submit");
        if (this.crypto.user_wallet_balance < this.product_price) {
          this.lackOfInventoryModal = true;
          return false;
        }
        this.confirmPurchaseModal = true;
      }
    },
    toggleConfirmSalesRequest() {
      this.showBuyModal = !this.showBuyModal;
      this.showSuccessMassage = false;
      if (!this.showBuyModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    async getCrypto() {
      try {
        let response = await axios("/crypto?search=Web money");
        this.crypto = response.data[0];
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.getCrypto();
  }
};
</script>

<style scoped>
#collapse-service {
  width: 100%;
}

.collapse-service__header {
  margin-bottom: 20px;
}

.collapse-service__container {
  padding: 0 10px;
}

.buy {
  margin-top: 30px;
}

.account-recharge-and-sales__wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*padding: 5px 20px;*/
  padding: 6px 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.account-recharge-and-sales__collapse-header {
  display: flex;
  align-items: center;
}

.account-recharge-and-sales__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  flex-basis: 94%;
  margin-right: 20px;
  font-family: "Vazir Medium FD";
}

.account-recharge-and-sales__content-wrapper {
  margin-top: 30px;
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

.buy-with__wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 0 10px;
}

.buy-with__header {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  padding: 30px 0 15px 0;
  text-align: center;
  position: relative;
  font-family: "Vazir Medium FD";
}

.buy-with__header::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  background-color: #1f3c88;
}

.buy-with__content-wrapper {
  /*padding: 20px 35px;*/
  padding: 5px 5px;
}

.buy-with__content {
  width: 100%;
  background: rgba(31, 60, 136, 0.1);
  min-height: 870px;
  border-radius: 10px;
  padding: 20px 20px 15px 20px;
}

.buy-with-content__header-card {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.buy-with-content__card-content {
  color: #121212;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  font-family: "Vazir Medium FD";
}

.buy-with-content__card-content span {
  font-family: "Vazir Medium FD";
}

.form-group {
  margin-top: 20px;
}

.input__label {
}

.input__wrapper {
  width: 100%;
}

.product-price-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
}

.input-link-wrapper {
  width: 100%;
}

.link-input {
  width: 100%;
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
}

.check-box-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.check-box-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
}

.i-have-account__text-information {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
}

.i-have-account__user-name-input-wrapper {
  width: 100%;
  margin-top: 5px;
}

.i-have-account__user-name-input {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

.i-have-account__password-input {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

.amount-input__image {
  display: none;
}

.none {
  display: none !important;
  height: unset !important;
}

.i-have-account-checked {
  background: rgba(255, 255, 255, 0.8);
  pointer-events: auto;
}

.description-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
}

.description-textarea {
  width: 100%;
  min-height: 111px;
  border-radius: 5px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.8);
  resize: none;
  padding: 10px;
}

.how-to-pay__header {
  font-size: 16px;
  color: #121212;
  margin-bottom: 30px;
}

.how-to-pay__radio-wrapper {
  display: flex;
  /*gap: 90px;*/
  gap: 30px;
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

label {
  font-size: 14px;
}

.how-to-pay__information {
  font-size: 12px;
  margin-top: 10px;
  color: #121212;
}

.how-to-pay__selected-section {
  margin-bottom: 20px;
}

.pay-button-wrapper {
  width: 100%;
  margin-top: 40px;
}

.pay-button {
  border: none;
  outline: none;
  padding: 4px 0;
  width: 100%;
  background: #1f3c88;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  border-radius: 5px;
  cursor: pointer;
}

.page-image-wrapper {
  float: left;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.page-image {
  width: 100%;
}

.account-recharge-and-sales__dropdown-icon {
  margin-left: 10px;
}

.account-recharge-and-sale_section {
  width: 100%;
}

.tips-and-warnings-section {
  width: 100%;
}

.rotate {
  transform: rotate(-90deg);
}

.account-recharge-and-sales__image {
  width: 40px;
  height: 40px;
}

@media (min-width: 960px) {
  .buy-with__content-wrapper {
    margin-top: unset;
    padding: 20px 35px;
  }

  .collapse-service__container {
    padding: 0 40px;
  }
}

#digitalCurrencyEquations {
  width: 100%;
}

.digital-currency-equations-container {
  padding: 0 10px;
}

.header {
  margin-bottom: 20px;
}

.top-slider {
  width: 100%;
  min-height: 168px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: #ffffff;
}

.slider-wrapper {
  min-height: 168px;
  display: flex;
  gap: 70px;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 81px;
}

.name {
  margin-top: 15px;
  font-weight: normal;
  font-size: 16px;
  color: rgba(18, 18, 18, 0.6);
}

.item-image {
  width: 60px;
}

.second-row {
  margin-top: 20px;
}

.tab-card {
  width: 100%;
  height: auto;
  /*min-height: 878px;*/
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*padding: 0 20px;*/
  padding: 0 5px;
}

.tab-header {
  display: flex;
  padding: 30px 0 13px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  /*width: 100%;*/
}

.tab-header::before {
  transition: right 0.2s linear, background-color 0.2s linear;
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background-color: #039100;
  position: absolute;
  bottom: 0;
  right: 0;
  /*border-bottom: 5px solid #1F3C88;*/
  border-radius: 5px;
  /*background: #DC0000;*/
}

.tab-header::before {
  content: "";
  display: block;
  height: 3px;
  width: 50%;
  background: rgba(7, 13, 89, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  background: #1f3c88;
}

.tab-header.left::before {
  right: 50%;
  background-color: #dc0000;
}

.tab-header::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: rgba(3, 145, 0, 0.2);
  position: absolute;
  bottom: 1px;
}

.tab-right-content {
  /*padding: 20px 45px;*/
  padding: 5px 5px;
}

.tab-left-content {
  /*padding: 20px 45px;*/
  padding: 5px;
}

.second-row {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

.column-one {
  width: 100%;
}

.column-two {
  width: 100%;
}

.right-tab {
  width: 50%;
  font-size: 14px;
}

.left-tab {
  width: 50%;
  font-size: 14px;
}

.information-wrapper {
  /*width: 100%;*/
  /*min-height: 1149px;*/
  /*background: #ffffff;*/
  /*box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);*/
  /*border-radius: 10px;*/
}

/*.right-tab-content-wrapper {*/
/*  width: 100%;*/
/*  min-height: 743px;*/
/*  background: rgba(3, 145, 0, 0.1);*/
/*  border-radius: 10px;*/
/*}*/

.panel-footer {
  margin-top: 20px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 1140px) {
  #collapse-service {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .digital-currency-equations-container {
    padding: 0 40px;
  }

  .buy-with__content-wrapper {
    margin-top: unset;
    padding: 20px 35px;
  }
}

@media (min-width: 768px) {
  .tab-right-content {
    padding: 20px 45px;
  }

  .tab-left-content {
    padding: 20px 45px;
  }
}

@media (min-width: 960px) {
  .column-one {
    width: 58%;
  }

  .column-two {
    width: 38%;
  }

  .tab-card {
    padding: 0 20px;
  }

  .page-image-wrapper {
    width: 100%;
  }

  .page-image-wrapper {
    width: 100%;
  }
}

@media (min-width: 560px) {
  .page-image {
    width: unset;
  }

  .right-tab {
    font-size: 16px;
  }

  .left-tab {
    font-size: 16px;
  }

  .how-to-pay__radio-wrapper {
    /*display: flex;*/
    gap: 90px;
    /*gap: px;*/
  }
}
</style>
